import React, { Component } from 'react';
import style from "./sidebar.module.css";
import userIcon from "../../assets/images/userico.png";
import { Link } from 'react-router-dom';

class Sidebar extends Component {

    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            visiblem: false,
            visiblemad: false,
            setClass: false,
            pathName: window.location.pathname,
            adminName: (JSON.parse(sessionStorage.getItem('authData')) ? JSON.parse(sessionStorage.getItem('authData')).adminName : ""),
            adminRole: (JSON.parse(sessionStorage.getItem('authData')) ? JSON.parse(sessionStorage.getItem('authData')).adminRole : ""),
            roleName: (JSON.parse(sessionStorage.getItem('authData')) ? JSON.parse(sessionStorage.getItem('authData')).adminRole.roleName : ""),
            rolePermissions: (JSON.parse(sessionStorage.getItem('authData')) ? JSON.parse(sessionStorage.getItem('authData')).adminRole.permissions : ""),
        };
        this.handClick = this.handClick.bind(this);
        this.handClickmaster = this.handClickmaster.bind(this);
        this.handClickadmin = this.handClickadmin.bind(this);
    }

    handClick() {
        this.setState(prevState => ({ visible: !prevState.visible }));
        this.setState({ visiblem: false, visiblemad: false });
    }
    handClickmaster() {
        this.setState(prevState => ({
            visiblem: !prevState.visiblem
        }));
        this.setState({ visible: false, visiblemad: false });
    }
    handClickadmin() {
        this.setState(prevState => ({
            visiblemad: !prevState.visiblemad
        }));
        this.setState({ visible: false, visiblem: false });
    }

    handleAddClass = () => {
        this.setState(prevState => ({
            setClass: !prevState.setClass
        }));
    }


    logout(e) {
        sessionStorage.clear();
        window.location.href = '/';
    }
    componentDidMount() {
        this.getpathlist();

    }
    componentDidUpdate(prevProps, prevState) {
        if (this.state.pathName !== window.location.pathname) {
            this.setState({ pathName: window.location.pathname }, this.getpathlist);
        }
    }


    getpathlist() {
        const { pathName } = this.state;


        if (this.state.pathName === "/holiday" || this.state.pathName === "/holiday/add/" || this.state.pathName === "/settings" || this.state.pathName === "/accountdetails" || this.state.pathName === "/social-links" || this.state.pathName === "/site-settings" || this.state.pathName === "/allergens" || this.state.pathName === "/allergens/add/" || this.state.pathName === `/allergens/edit/${this.state.pathName.split('/').at(-1)}` || this.state.pathName === "/ingredients" || this.state.pathName === `/ingredients/edit/${this.state.pathName.split('/').at(-1)}` || this.state.pathName === "/ingredients/add/" || this.state.pathName === "/stripe-paymentkey") {
            this.setState(prevState => ({
                visible: !prevState.visible
            }));
        }

        if ([
            `/master-entry/productgroup`, `/master-entry/add/productgroup`, `/master-entry/edit/productgroup/${pathName.split('/').at(-1)}`,
            `/master-entry/taxcode`, `/master-entry/add/taxcode`, `/master-entry/edit/taxcode/${pathName.split('/').at(-1)}`,
            `/master-entry/suitablefor`, `/master-entry/add/suitablefor`, `/master-entry/edit/suitablefor/${pathName.split('/').at(-1)}`
        ].includes(pathName)) {
            this.setState({ visiblem: true, visible: false });
        }

        if ([
            `/role`, `/role/editRoles/${this.state.pathName.split('/').at(-1)}`, `/account`
        ].includes(pathName)) {
            this.setState({ visiblemad: true, visible: false });
        }

    }

    render() {

        return (
            <>


                <div className={`${style.dashRight} ${this.state.setClass ? 'open' : ''}`}>
                    <div className={`${style.sideNav}`}>
                        <div className={style.innside}>
                            <div className={style.userIco}>
                                <span>
                                    <img src={userIcon} alt='user' />
                                </span>
                                {this.state.adminName &&
                                    <h5 className={style.title}>{this.state.adminName}</h5>
                                }
                            </div>
                            <a className={style.menuOpen} onClick={this.handleAddClass}>
                                <span><i className={`fa fa-angle-left`} ></i></span>
                            </a>
                            <nav>
                                <ul>
                                    {/* <li className={(this.state.pathName === "/dashboard" ? "active" : "")}>
                                        <Link to="/dashboard"><i className={`fa fa-th-large`}></i> <span className={style.menuTxt}>Dashboard</span></Link>
                                    </li> */}
                                    {this.state.rolePermissions.includes('manage_products') &&
                                        <li className={(this.state.pathName === "/products" ? "active" : "")}> <Link to="/products" ><i className={`fa fa-archive`}></i> <span className={style.menuTxt}>Products</span></Link>
                                        </li>

                                    }
                                    {this.state.rolePermissions.includes('manage_freeproducts') &&
                                        <li className={((this.state.pathName === "/freeproducts" || this.state.pathName === "/freeproducts/addfreeproducts") ? "active" : "")}> <Link to="/freeproducts"><i className={`fa fa-sliders`}></i> <span className={style.menuTxt}>Free Products Setup</span></Link>
                                        </li>
                                    }

                                    {this.state.rolePermissions.includes('manage_newproducts') &&
                                        <li className={((this.state.pathName === "/newproducts" || this.state.pathName === "/newproducts/add" || this.state.pathName === `/newproducts/edit/${this.state.pathName.split('/').at(-1)}`) ? "active" : "")}> <Link to="/newproducts"><i className={`fa fa-cutlery`}></i> <span className={style.menuTxt}>New Products</span></Link>
                                        </li>
                                    }

                                    {this.state.rolePermissions.includes('manage_customers') &&
                                        <li className={(this.state.pathName === "/vendors" ? "active" : "")}>
                                            <Link to="/vendors"><i className={`fa fa-users`}></i> <span className={style.menuTxt}>Customers</span></Link>
                                        </li>
                                    }
                                    {this.state.rolePermissions.includes('manage_orders') &&
                                        <li className={(this.state.pathName === "/orders" ? "active" : "")}>
                                            <Link to="/orders"><i className={`fa fa-shopping-cart`}></i> <span className={style.menuTxt}>Orders</span></Link>
                                        </li>
                                    }
                                    {this.state.rolePermissions.includes('manage_settings') &&
                                        <li className={(this.state.pathName === "/stripe-paymentlog" ? "active" : "")}>
                                            <Link to="/stripe-paymentlog"><i className={`fa fa-cc-stripe`}></i> <span className={style.menuTxt}>Stripe Payment Log</span></Link>
                                        </li>

                                    }
                                    {this.state.rolePermissions.includes('manage_masterentry') &&
                                        <li className={`${style.dropMenu} ${this.state.visiblem ? 'active show' : ''}`}>
                                            <a href={void (0)} onClick={this.handClickmaster}><i className={`fa fa-trophy`}></i>  <span className={style.menuTxt}>Master Entry </span> <i className="fa fa-angle-down"></i></a>

                                            <div className={style.submenu}>
                                                <ul>
                                                    <li className={((this.state.pathName === "/master-entry/productgroup" || this.state.pathName === "/master-entry/add/productgroup" || this.state.pathName === `/master-entry/edit/productgroup/${this.state.pathName.split('/').at(-1)}`) ? "active" : "")}>
                                                        <Link to="/master-entry/productgroup"><i className={`fa fa-database`}></i>  <span className={style.menuTxt}>Product Group</span></Link>
                                                    </li>
                                                    <li className={((this.state.pathName === "/master-entry/taxcode" || this.state.pathName === "/master-entry/add/taxcode" || this.state.pathName === `/master-entry/edit/taxcode/${this.state.pathName.split('/').at(-1)}`) ? "active" : "")}>
                                                        <Link to="/master-entry/taxcode"><i className={`fa fa-barcode`}></i>  <span className={style.menuTxt}>Tax Code</span></Link>
                                                    </li>
                                                    <li className={((this.state.pathName === "/master-entry/suitablefor" || this.state.pathName === "/master-entry/add/suitablefor" || this.state.pathName === `/master-entry/edit/suitablefor/${this.state.pathName.split('/').at(-1)}`) ? "active" : "")}>
                                                        <Link to="/master-entry/suitablefor"><i className={`fa fa-flag-o`}></i>  <span className={style.menuTxt}>Suitable For</span></Link>
                                                    </li>

                                                </ul>
                                            </div>

                                        </li>
                                    }


                                    {this.state.rolePermissions.includes('manage_users') &&
                                        <li className={`${style.dropMenu} ${this.state.visiblemad ? 'active show' : ''}`}>
                                            <a href={void (0)} onClick={this.handClickadmin}><i className={`fa fa-address-book`}></i>  <span className={style.menuTxt}>Admin Users </span> <i className="fa fa-angle-down"></i></a>

                                            <div className={style.submenu}>
                                                <ul>
                                                    <li className={((this.state.pathName === "/account") ? "active" : "")}>
                                                        <Link to="/account"><i className={`fa fa-user-o`}></i><span className={style.menuTxt}>Users</span></Link>
                                                    </li>
                                                    {this.state.rolePermissions.includes('manage_roles') && <li className={((this.state.pathName === "/role" || this.state.pathName === `/role/editRoles/${this.state.pathName.split('/').at(-1)}`) ? "active" : "")}>
                                                        <Link to="/role"><i className={`fa fa-level-up`}></i><span className={style.menuTxt}>Roles</span></Link>
                                                    </li>}

                                                </ul>
                                            </div>
                                        </li>
                                    }

                                    {this.state.rolePermissions.includes('manage_settings') &&
                                        <li className={`${style.dropMenu} ${this.state.visible ? 'active show' : ''}`}>
                                            <a href={void (0)} onClick={this.handClick}><i className={`fa fa-gear`}></i>  <span className={style.menuTxt}>Settings</span> <i className="fa fa-angle-down"></i></a>
                                            <div className={style.submenu}>
                                                <ul>

                                                    <li className={((this.state.pathName === "/holiday" || this.state.pathName === "/holiday/add/") ? "active" : "")}>
                                                        <Link to="/holiday"><i className={`fa fa-calendar`}></i>  <span className={style.menuTxt}>Holidays</span></Link>
                                                    </li>
                                                    <li className={(this.state.pathName === "/settings" ? "active" : "")}>
                                                        <Link to="/settings"><i className={`fa fa-wrench`}></i>  <span className={style.menuTxt}>Website Maintenance</span></Link>
                                                    </li>
                                                    <li className={((this.state.pathName === "/allergens" || this.state.pathName === "/allergens/add/" || this.state.pathName === `/allergens/edit/${this.state.pathName.split('/').at(-1)}`) ? "active" : "")}>
                                                        <Link to="/allergens"><i className={`fa fa-industry`}></i> <span className={style.menuTxt}>Allergens</span></Link>
                                                    </li>
                                                    <li className={((this.state.pathName === "/ingredients" || this.state.pathName === "/ingredients/add/" || this.state.pathName === `/ingredients/edit/${this.state.pathName.split('/').at(-1)}`) ? "active" : "")}>
                                                        <Link to="/ingredients"><i className={`fa fa-magic`}></i> <span className={style.menuTxt}>Ingredients</span></Link>
                                                    </li>
                                                    <li className={(this.state.pathName === "/stripe-paymentkey" ? "active" : "")}>
                                                        <Link to="/stripe-paymentkey"><i className={`fa fa-cc-stripe`}></i> <span className={style.menuTxt}>Stripe Payment Key</span></Link>
                                                    </li>
                                                    <li className={(this.state.pathName === "/social-links" ? "active" : "")}>
                                                        <Link to="/social-links"><i className={`fa fa-globe`}></i> <span className={style.menuTxt}>Social Links</span></Link>
                                                    </li>
                                                    <li className={(this.state.pathName === "/site-settings" ? "active" : "")}>
                                                        <Link to="/site-settings"><i className={`fa fa-gear`}></i> <span className={style.menuTxt}>Site Settings</span></Link>
                                                    </li>
                                                </ul>
                                            </div>


                                        </li>
                                    }
                                    {/* <li className={(this.state.pathName==="/settings"?"active":"")}>
                                    <Link to="/settings"><i className={`fa fa-gear`}></i> Settings</Link>
                                </li>
                                
                                <li className={(this.state.pathName==="/holiday"?"active":"")}>
                                    <Link to="/holiday"><i className={`fa fa-calendar`}></i> Holidays</Link>
                                </li> */}

                                    {/* <li className={`${style.dropMenu} ${this.state.visible ? 'show': ''}`}>
                                    <a href={void(0)} onClick={this.handClick}>SubMenu <i className="fa fa-angle-down"></i></a>
                                    <div className={style.submenu}>
                                        <ul>
                                            <li>
                                                <a href={void(0)}>SubMenu 1</a>
                                            </li>
                                            <li className="active">
                                                <a href={void(0)}>SubMenu 2</a>
                                            </li>
                                            <li>
                                                <a href={void(0)}>SubMenu 3</a>
                                            </li>
                                            <li>
                                                <a href={void(0)}>SubMenu 4</a>
                                            </li>
                                            <li>
                                                <a href={void(0)}>SubMenu 5</a>
                                            </li>
                                        </ul>
                                    </div>
                                </li> */}
                                </ul>
                            </nav>
                            <div className={style.navBot}>
                                <a href={void (0)} onClick={(e) => this.logout(e)}>
                                    <i className={`fa fa-sign-out`}></i> <span className={style.menuTxt}>Logout</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }

}

export default Sidebar;