import DataTable from 'react-data-table-component';
export const TopCustomers = ({ customers }) => {

    const priceFormat = (price) => {
        return price.toLocaleString("en-US", {
            style: "currency",
            currency: "GBP",
            minimumFractionDigits: 2,
        });
    };

    const columns = [
        {
            name: "Account Code",
            selector: row => row.accountCode,
            sortable: false,
        },
        {
            name: "Account Name",
            selector: row => row.accountName,
            sortable: false,
        },
        {
            name: "Ordered Amount",
            selector: row => priceFormat(row.sum),
            sortable: false,
        }

    ];

    const customStyles = {
        rows: {
            style: {
                minHeight: '25px',
            },
        },
        headCells: {
            style: {
                padding: '8px', // override the cell padding for head cells

            },
        },
        cells: {
            style: {
                padding: '8px', // override the cell padding for data cells

            },
        },
    };


    return (
        <div className="chart-container" style={{ verticalAlign: "top" }}>
            <h2 style={{ textAlign: "center", verticalAlign: "top" }}>High-Value Customers</h2>
            <DataTable
                columns={columns}
                data={customers}
                customStyles={customStyles}
                pagination={false}


            />
        </div>
    );
};