import React, { Component, createRef } from "react";
import Header from "../common/layout/header";
import Footer from "../common/layout/footer";
import { BaseUrl } from "../common/config";
import discountIcon from "../assets/images/discount-icon.png";
import { http } from "../helper";
import DataTable from "react-data-table-component";
import Sidebar from "../common/sidebar";
import moment from "moment";
import CommonAlert from "../common/Commonalert";
import Popup from "../common/popup";
import Select from "react-select";
import DatePicker from "react-datepicker";
import { format } from "date-fns";
import { debounce } from "lodash";
import { dateformatwithuktime } from "../utilityfunctions";

class Orders extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orders: [],
      temporders: [],
      searchTextVal: "",
      loading: true,
      popupView: "",
      alertMessage: "",
      alertPopup: false,
      isElementVisible: false,
      datelabel: "Past 1 Month",
      datefilterlabel: "Past 1 Month",
      prevDatelabel: "",
      dateRange: "",
      datepickershow: false,
      datefrom: "",
      dateto: "",
      datefromLabel: "",
      datetoLabel: "",
      dateinvalid_error: false,
      datefill_error: false,
      searchstate: false,
      searchField: "OrderNumber",
      searchFieldtxt: "Order Number",
      notesPopup: false,
      note: "",
      currentOrder: [],
      orderlogs: [],
      noteError: false,
      isLoading: false,
      uploadMessage: "",
      showPopup: false,
      refreshLoading: false,
    };
    this.searchFieldRef = createRef();
    this.searchInputRef = createRef();

    if (!sessionStorage.getItem("isLoggedIn")) window.location.href = "/";

    if (
      JSON.parse(sessionStorage.getItem("authData")) != "" &&
      !JSON.parse(
        sessionStorage.getItem("authData")
      ).adminRole.permissions.includes("manage_orders")
    ) {
      window.location.href = "/unauthorized";
    }
    this.dropdownRef = React.createRef();
    this.dropdownMobileRef = React.createRef();
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.updateNoteLog = this.updateNoteLog.bind(this);
    this.hideNotePopup = this.hideNotePopup.bind(this);

    this.openFilter = this.openFilter.bind(this);
    // Initialize debounced search function
    this.debouncedSearch = debounce(this.getAllOrdersforsearch, 500); // Adjust debounce delay as needed
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
    this.getAllOrders();
    this.interval = setInterval(() => {
      this.setState({ refreshLoading: true });
      this.getAllOrders();
    }, 300000); // 5 minutes in milliseconds
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
    clearInterval(this.interval);
  }

  handleClickOutside = (event) => {
    if (this.dropdownRef && !this.dropdownRef.current.contains(event.target)) {
      this.setState({ isElementVisible: false });
    }
  };
  openFilter = () => {
    this.setState((prevState) => ({
      isElementVisible: !prevState.isElementVisible,
    }));
  };
  getAllOrders() {
    let authData = { _id: "" };
    authData = JSON.parse(sessionStorage.getItem("authData"));

    var loginDetails = {};
    if (sessionStorage.getItem("authData")) {
      loginDetails = JSON.parse(sessionStorage.getItem("authData"));
    }

    var dateval = "";
    var dateval2 = "";

    if (this.state.datefrom && this.state.dateto) {
      dateval = this.state.datefrom ? new Date(this.state.datefrom) : "";
      dateval = format(dateval, "yyyy-MM-dd");
      dateval2 = new Date(this.state.dateto);
      dateval2 = format(dateval2, "yyyy-MM-dd");
    }

    let payload = {
      orderdays:
        this.state.dateRange && typeof (this.state.dateRange === "number")
          ? this.state.dateRange
          : this.state.dateRange && typeof (this.state.dateRange === "string")
          ? ""
          : 30,
      datefrom: dateval ? dateval : "",
      dateto: dateval2 ? dateval2 : "",
      from: "backend",
    };
    let URL = BaseUrl + "admin/orders";

    http
      .post(URL, payload)
      .then((response) => {
        let responseData = [];
        responseData = response.data.data;

        this.setState({
          orders: responseData,
          loading: false,
          temporders: responseData,
          alertPopup: false,
          alertMessage: "",
          refreshLoading: false,
        });
        var element = document.querySelectorAll(".fa-refresh");
        element.forEach((row) => {
          row.classList.remove("fa-spin");
        });
      })
      .catch((err) => {
        var element = document.querySelectorAll(".fa-refresh");
        element.forEach((row) => {
          row.classList.remove("fa-spin");
        });
        this.setState({
          alertPopup: true,
          loading: false,
          alertMessage: `The Server is busy, please try after sometime`,
        });
      });
  }

  getAllOrdersforsearch() {
    this.setState({ alertPopup: false, searchstate: true });
    let authData = JSON.parse(sessionStorage.getItem("authData"));
    //accountCode: authData.accountCode,
    var loginDetails = {};
    if (sessionStorage.getItem("authData")) {
      loginDetails = JSON.parse(sessionStorage.getItem("authData"));
    }

    let URL =
      BaseUrl +
      "admin/searchorders/" +
      this.state.searchField +
      "/" +
      encodeURIComponent(this.state.searchTextVal);
    http
      .get(URL)
      .then((response) => {
        let responseData = [];
        responseData = response.data.data;

        this.setState({ orders: responseData, loading: false });
        var element = document.querySelectorAll(".fa-refresh");
        element.forEach((row) => {
          row.classList.remove("fa-spin");
        });
      })
      .catch((err) => {
        this.setState({ loading: false });
        var element = document.querySelectorAll(".fa-refresh");
        element.forEach((row) => {
          row.classList.remove("fa-spin");
        });
        //reject(err); // Reject with error
      });
  }

  priceFormat(price) {
    return price.toLocaleString("en-US", {
      style: "currency",
      currency: "GBP",
      minimumFractionDigits: 2,
    });
  }

  vatAmountCalcuation(order) {
    //var old_order = order;
    var totalAmount = 0;
    order.items.map((item, value) => {
      if (item.TaxCode !== "" && item.TaxCode === "T1") {
        totalAmount += item.LinePrice;
      }
    });
    return totalAmount;
  }
  /* ************ Added by K2b Sangeetha to get the account details of the particular order ******************* */
  getOrderaccountdetails(accountCode, orderrow) {
    if (orderrow.accountEmail !== undefined) {
      this.setState(
        {
          oaccountName: orderrow.accountName,
          oaccountEmail: orderrow.accountEmail,
          oaccountAddress: orderrow.accountAddress,
        },
        () => this.viewOrderDetails(orderrow) // Callback to ensure state is updated
      );
    } else {
      var loginDetails = {};
      if (sessionStorage.getItem("authData")) {
        loginDetails = JSON.parse(sessionStorage.getItem("authData"));
      }
      let URL = BaseUrl + "admin/account/" + accountCode;
      http
        .get(URL)
        .then((response) => {
          let responseData = [];
          responseData = response.data.data;
          let addressFields = [
            responseData.accountAddress1,
            responseData.accountAddress2,
            responseData.accountAddress3,
            responseData.accountAddress4,
            responseData.accountAddress5,
          ];
          this.setState({
            oaccountName: responseData.accountName,
            oaccountEmail: responseData.accountEmail,
            oaccountAddress: addressFields
              .filter((field) => field !== undefined && field != "")
              .join(", "),
          });
          this.viewOrderDetails(orderrow);
        })
        .catch((err) => {
          this.setState({ loading: false });
          //reject(err); // Reject with error
        });
    }
  }
  closePopup = () => {
    this.setState({ showPopup: false, uploadMessage: "" });
  };
  viewOrderDetails(order) {
    var ordertotal = 0;
    var subTotal = 0;
    this.setState({ alertPopup: false, alertMessage: "" });

    // Sort order items to move those with UnitPrice 0 to the end
    const sortedItems = [...order.items].sort((a, b) => {
      if (a.UnitPrice === 0 && b.UnitPrice !== 0) return 1;
      if (a.UnitPrice !== 0 && b.UnitPrice === 0) return -1;
      return 0;
    });


    sortedItems.map((item) => {
      ordertotal += item.LinePrice;
    });
    sortedItems.map((item) => {
      subTotal += item.UnitPrice * item.QuantityOrdered;
    });
    var vatTotalAmount = this.vatAmountCalcuation(order);

    var vatAmount = 0;
    if (vatTotalAmount > 0) {
      vatAmount = (vatTotalAmount / 100) * 20;
      ordertotal = ordertotal + vatAmount;
    }

    let popupResult = (
      <Popup
        extClass="order-pop my-order-details-pop"
        headMoreTxt={
          <>
            <div className="order-top">
              <div className="bottom order-flex">
                {/* Changed for getting account details of particular order  */}
                <div className="order_no_details">
                  <h3>Order No - {order.OrderNumber}</h3>
                  <p>
                    Order Placed on:{" "}
                    <span className="date">
                      {" "}
                      {moment(order.createdAt).format("DD/MM/YYYY hh:mm A")}
                    </span>{" "}
                  </p>
                  <p>
                    Order Delivery on:{" "}
                    <span className="date"> {order.DateRequired} </span>
                  </p>

                  {order.PaymentMethod === "A" && (
                    <p>
                      Payment Method:
                      <span className="pay">
                        {" "}
                        {order.OrderPaid === "YES"
                          ? "Paid (Pay Later)"
                          : "Pay Later"}
                      </span>
                    </p>
                  )}
                  {order.PaymentMethod === "K" && (
                    <p>
                      Payment Method:
                      <span className="pay"> Online Payment</span>
                    </p>
                  )}

                  <p>
                    Status:<span className="pay"> {order.OrderStatus}</span>
                  </p>
                </div>

                <div className="order_account_details">
                  <p>
                    Name:{" "}
                    <span className="date">{this.state.oaccountName}</span>{" "}
                  </p>
                  <p>
                    Account Code:{" "}
                    <span className="date">{order.accountCode}</span>{" "}
                  </p>
                  <p>
                    Email Address:{" "}
                    <span className="pay">{this.state.oaccountEmail}</span>
                  </p>
                  <p>
                    Address:{" "}
                    <span className="pay">{this.state.oaccountAddress}</span>
                  </p>
                </div>
                {/* Changed for getting account details of particular order  */}

                {/* <h3>Order No - {order.OrderNumber}</h3>
                            <p>Order Placed at: <span className="date">{moment(order.createdAt).format('DD/MM/YYYY HH:MM A')}</span> </p>
                            <p>Order Delivery at: <span className="date">{order.DateRequired}</span> </p>
                            <p>Payment Method: <span className="pay">{order.PaymentMethod==='A'?'Pay later':'Online Payment'}</span></p> */}
              </div>
            </div>
          </>
        }
        closebtn={
          <>
            <div className="pop-header-top order-top">
              <div className="head">
                <h4>Order Details - {order.OrderNumber}</h4>
              </div>
              <span
                onClick={() => this.setState({ popupView: "" })}
                className="close"
              ></span>
            </div>
          </>
        }
        bodyMoreTxt={
          <>
            <div className="modal-table">
              <h4 className="item-title">Ordered Items</h4>
              <div className="checkout-table">
                <div className="table_overflow">
                  <table>
                    <thead>
                      <tr>
                        <td>Product</td>
                        <td>Unit of Sale</td>
                        <td>Quantity</td>
                        <td align="center">Price</td>
                        <td align="right">Total</td>
                      </tr>
                    </thead>
                    <tbody>
                      {/* Added Special offer and product number like frontend by k2b Sangeetha   */}
                      {sortedItems.map((item, index) => {
                        return (
                          item.QuantityOrdered > 0 && (
                            <tr key={index}
                            className={item.UnitPrice === 0 ? 'free-prod-bg' : ''}>
                              <td
                                data-title="Product"
                                className="data-product-hide"
                              >
                                {this.str_replace(item.Description)} (
                                {item.ProductNumber.includes("_")
                                  ? item.ProductNumber.substring(
                                      0,
                                      item.ProductNumber.indexOf("_")
                                    )
                                  : item.ProductNumber}
                                )
                                {item.TaxCode !== "" &&
                                  item.TaxCode === "T1" && (
                                    <span className="color-red">*</span>
                                  )}
                                {item.OfferFlag == "true" && (
                                  <span className="offer-tag-wrap">
                                    <span className="offer-tag">
                                      <img
                                        src={discountIcon}
                                        alt="Offer"
                                        className="tag-icon"
                                      />
                                      Special Offer
                                    </span>
                                  </span>
                                )}
                              </td>
                              <td data-title="Unit of Sale">
                                {item.PricingUnit}
                              </td>
                              <td data-title="Quantity">
                                {item.QuantityOrdered}
                              </td>
                              <td data-title="Price" align="center">
                                {this.priceFormat(item.UnitPrice)}
                              </td>
                              <td data-title="Total" align="right">
                                {this.priceFormat(item.LinePrice)}
                              </td>
                            </tr>
                          )
                        );
                      })}
                      {/* Added Special offer and product number like frontend by k2b Sangeetha   */}
                    </tbody>
                    <thead className="ord-grand-total">
                      {order.OrderNote !== "" && order.OrderNote !== null && (
                        <tr>
                          <td align="left" width={120} colSpan="7">
                            Order Notes:{" "}
                            <span className="order_remarks">
                              {" "}
                              {this.getOrderNotes(order.OrderNote)}
                            </span>
                          </td>
                        </tr>
                      )}
                      <tr>
                        <td align="right" width={120} colSpan="4">
                          Sub Total:
                        </td>
                        <td align="right">{this.priceFormat(subTotal)}</td>
                      </tr>
                      {vatAmount > 0 && (
                        <tr>
                          <td
                            align="right"
                            width={120}
                            colSpan="4"
                            className="td-vat-fee"
                          >
                            <span className="strong">
                              VAT{" "}
                              <span className="vat-txt">
                                (20% of{" "}
                                <span className="vat-amt">
                                  {this.priceFormat(vatTotalAmount)}
                                </span>
                                )<span className="color-red">*</span>:
                              </span>
                            </span>
                          </td>
                          <td className="cart-prod-vat" align="right">
                            {this.priceFormat(vatAmount)}
                          </td>
                        </tr>
                      )}

                      <tr>
                        <td align="right" width={120} colSpan="4">
                          Shipping:
                        </td>
                        <td align="right">
                          <span className="color-red">FREE</span>
                        </td>
                      </tr>

                      <tr>
                        <td align="right" width={120} colSpan="4">
                          Grand Total:
                        </td>
                        <td align="right">{this.priceFormat(ordertotal)}</td>
                      </tr>
                    </thead>
                  </table>
                </div>
              </div>
            </div>
          </>
        }
      />
    );

    this.setState({ popupView: popupResult });
  }

  str_replace(product_name) {
    if (product_name !== "") {
      product_name = product_name.replaceAll("amp;", "&");
      product_name = product_name.replaceAll("apos;'", "'' ");
      product_name = product_name.replaceAll("quot;'", "'' ");

      return product_name;
    } else {
      return product_name;
    }
  }

  getOrderNotes(OrderNote) {
    var note_test = OrderNote.replaceAll(" PIXHALLCSL", "");
    note_test = note_test.replaceAll("amp;", "&");
    note_test = note_test.replaceAll("apos;", "'' ");
    note_test = note_test.replaceAll("apos;'", "'' ");
    note_test = note_test.replaceAll("quot;'", "'' ");
    note_test = note_test.replaceAll("quot;", "'' ");
    note_test = note_test.replaceAll("'' '", "'' ");
    return note_test;
  }

  handleReset = () => {
    this.setState({
      orders: this.state.temporders,
      searchstate: false,
      searchField: "",
      searchFieldtxt: "Order Number",
      searchTextVal: "",
    });
    if (this.searchFieldRef.current) {
      this.searchFieldRef.current.selectedIndex = 1;
    }
    if (this.searchInputRef.current) {
      this.searchInputRef.current.value = "";
    }
  };

  // handleChange = async(value) => {
  //     this.setState({searchTextVal:value});
  //     //this.filterData(value);
  //   };

  handleChange = (e) => {
    const value = e.target.value;
    this.setState({ searchTextVal: value });
    // Cancel any previously scheduled debounced search and then schedule a new one
    this.debouncedSearch.cancel();
    this.debouncedSearch();
  };
  handleNoteChange = async (value) => {
    this.setState({ note: value.trim(), noteError: false });
  };

  filterData = async (value) => {
    // e.preventDefault();
    const lowercasedValue = value.toLowerCase().trim();
    let filteredData = [];
    filteredData = this.state.temporders.filter((item) => {
      return item.OrderNumber.toLowerCase().includes(lowercasedValue);
    });

    if (filteredData.length > 0) {
      this.setState({ orders: filteredData });
    } else {
      this.getAllOrdersforsearch();
    }
  };

  orderTotal(items) {
    var ordertotal = 0;

    items.map((item) => {
      ordertotal += item.LinePrice;
    });
    return this.priceFormat(ordertotal);
  }
  orderTotalValue(items) {
    return items.reduce((total, item) => total + item.LinePrice, 0);
  }

  orderRefresh(order) {
    var loginDetails = {};
    if (sessionStorage.getItem("authData")) {
      loginDetails = JSON.parse(sessionStorage.getItem("authData"));
    }

    var current = this;
    var numberOfAjaxCAllPending = 0;
    var timeInterval = 20000;
    var element = document.getElementById(
      "refresh_order_id" + order.OrderNumber
    );
    element.classList.add("fa-spin");

    if (order.OrderStatus !== "Invoiced") {
      this.setState({ alertPopup: false });
      let URL =
        BaseUrl +
        "updateorderitems/" +
        order.accountCode +
        "/" +
        order.OrderNumber;
      http
        .get(URL)
        .then((response) => {
          numberOfAjaxCAllPending++;
          let responseData = [];
          responseData = response.data.data[0];
          current.setState({ alertPopup: false, loading: false });
          if (this.state.searchstate != false) {
            current.getAllOrdersforsearch();
          } else {
            current.getAllOrders();
          }
          clearInterval(myInterval);
        })
        .catch((err) => {
          console.log(err);
          current.setState({ alertPopup: true });
        });
      var myInterval = setInterval(function () {
        if (numberOfAjaxCAllPending == 0) {
          numberOfAjaxCAllPending++;
          timeInterval = "";
          current.setState({ alertPopup: true, cartExistPopup: false });
        }
      }, timeInterval);
    } else {
      if (this.state.searchstate != false) {
        this.getAllOrdersforsearch();
      } else {
        this.getAllOrders();
      }
      this.setState({ alertPopup: false, loading: false });

      //});
    }
  }

  applyOrderFilters() {
    let authData = { _id: "" };
    let dateinvalid = false; //Added by K2b Sangeetha for date range error
    authData = JSON.parse(sessionStorage.getItem("authData"));
    if (this.state.dateRange !== "") {
      this.setState({
        isElementVisible: false,
        loading: true,
        alertPopup: false,
        isElementVisibleMobile: false,
        dateinvalid_error: false,
        datefill_error: false,
      });

      var payload = {
        orderdays: this.state.dateRange,
        datefrom: "",
        dateto: "",
      };

      if (this.state.dateRange === "custom") {
        if (this.state.datefrom && this.state.dateto) {
          var dateval = new Date(this.state.datefrom);
          dateval = format(dateval, "yyyy-MM-dd");

          var dateval2 = new Date(this.state.dateto);
          dateval2 = format(dateval2, "yyyy-MM-dd");

          this.setState({
            datefromLabel: this.state.datefrom,
            datetoLabel: this.state.dateto,
            datefill_error: false,
          });

          payload = {
            orderdays: "",
            datefrom: dateval,
            dateto: dateval2,
          };
        } else {
          dateinvalid = true;
          this.setState({
            datefill_error: true,
            isElementVisible: true,
            loading: false,
          });
        }
      } else {
        this.setState({ datelabel: this.state.datefilterlabel });
        payload = {
          orderdays: this.state.dateRange,
          datefrom: "",
          dateto: "",
        };
        this.setState({
          datefromLabel: "",
          datetoLabel: "",
        });
      }
      if (!dateinvalid) {
        var loginDetails = {};
        if (sessionStorage.getItem("authData")) {
          loginDetails = JSON.parse(sessionStorage.getItem("authData"));
        }

        let URL = BaseUrl + "admin/orders";
        // Send the GET request with parameters in the 'params' property

        http
          .post(URL, payload)
          .then((response) => {
            let responseData = [];
            responseData = response.data.data;

            this.setState({
              orders: responseData,
              temporders: responseData,
              loading: false,
            });
          })
          .catch((err) => {
            console.log("Error:", err); // Log the error

            this.setState({ loading: false });
          });
      }
    }
  }

  clearFilterOrderSearch() {
    this.setState(
      { isElementVisible: false, isElementVisibleMobile: false, dateRange: 3 },
      function () {
        //this.getAllOrders();
      }
    );
  }
  handleFilterChange = (e) => {
    if (e.value !== "") {
      var valueDates = "";

      if (e.value == "1") {
        valueDates = 30;
        this.setState({
          datepickershow: false,
          datefilterlabel: "Past 1 Month",
        });
      } else if (e.value == "3") {
        valueDates = 30 * 3;
        this.setState({
          datepickershow: false,
          datefilterlabel: "Past 3 Months",
        });
      } else if (e.value == "6") {
        valueDates = 30 * 6;
        this.setState({
          datepickershow: false,
          datefilterlabel: "Past 6 Months",
        });
      } else if (e.value == "1Year") {
        valueDates = 365;
        this.setState({
          datepickershow: false,
          datefilterlabel: "Past 1 Year",
        });
      } else if (e.value == "custom") {
        valueDates = "custom";

        this.setState({ datepickershow: true, datefilterlabel: "" });
      }
      this.setState({ dateRange: valueDates, dateinvalid_error: false });
    }
  };
  DateChange(value, type) {
    if (type == "from") {
      this.setState({ datefrom: value, dateinvalid_error: false });
    } else if (type == "to") {
      var dateval = new Date(this.state.datefrom);
      dateval = format(dateval, "yyyy-MM-dd");

      var dateval2 = new Date(value);
      dateval2 = format(dateval2, "yyyy-MM-dd");

      if (dateval > dateval2) {
        this.setState({ dateto: "", dateinvalid_error: true });
      } else {
        this.setState({ dateto: value, dateinvalid_error: false });
      }
    }
  }
  /*added by k2bv */
  formatDate(dateString) {
    const dateObject = new Date(dateString);
    const year = dateObject.getFullYear();
    const month = String(dateObject.getMonth() + 1).padStart(2, "0");
    const day = String(dateObject.getDate()).padStart(2, "0");
    return `${day}/${month}/${year}`;
  }
  /*added by k2bv */

  /*added by K2b Sangeetha */
  handleSearchFieldChange = (event) => {
    //  this.setState({ searchField: event.target.value,searchFieldtxt:event.target.text});
    const selectedOption = this.searchFieldRef.current.selectedOptions[0];
    const textval = selectedOption ? selectedOption.innerText : "";

    //const textval = event.target.selectedOptions[0].innerText;
    this.setState({
      searchField: this.searchFieldRef.current.value,
      searchFieldtxt: textval,
      searchTextVal: "",
    });

    // Clear the input value
    if (this.searchInputRef.current) {
      this.searchInputRef.current.value = "";
    }
  };

  handleSearchFieldChange1 = (event) => {
    //  this.setState({ searchField: event.target.value,searchFieldtxt:event.target.text});
    const textval = event.target.value;

    this.setState({ searchTextVal: textval }, () => {
      // Call getAllOrdersforsearch after updating the state
      this.getAllOrdersforsearch();
    });
  };

  renderSearchInput = () => {
    const { searchTextVal } = this.state;
    const searchField = this.searchFieldRef.current
      ? this.searchFieldRef.current.value
      : "";

    if (searchField === "PaymentMethod") {
      return (
        <div className="orders-dropdown">
          <select
            className="search-ord"
            ref={this.searchInputRef}
            onChange={this.handleSearchFieldChange1}
          >
            <option value="">Select</option>
            <option value="K">Online Payment</option>
            <option value="A">Pay Later</option>
          </select>
        </div>
      );
    } else if (searchField === "OrderStatus") {
      return (
        <div className="orders-dropdown">
          <select
            className="search-ord"
            ref={this.searchInputRef}
            onChange={this.handleSearchFieldChange1}
          >
            <option value="">Select</option>
            <option value="New Order">New Order</option>
            <option value="Acknowledged">Acknowledged</option>
            <option value="Invoiced">Invoiced</option>
            <option value="Part Desp.  Inv.">Part Desp. Inv.</option>
          </select>
        </div>
      );
    } else {
      return (
        <input
          type="text"
          ref={this.searchInputRef}
          onChange={this.handleChange}
          placeholder={`Search by ${this.state.searchFieldtxt}`}
        />
      );
    }
  };
  /*added by K2b Sangeetha */

  orderNotelog = (order) => {
    this.setState({ isLoading: true });
    var loginDetails = {};
    let responseData = [];
    if (sessionStorage.getItem("authData")) {
      loginDetails = JSON.parse(sessionStorage.getItem("authData"));
    }

    let URL = BaseUrl + "admin/orderlog/" + order.OrderNumber;
    // Start loading
    this.setState({ isLoading: true, notesPopup: true, currentOrder: order });

    http
      .get(URL)
      .then(async (response) => {
        responseData = response.data.data;

        this.setState({
          isLoading: false,
          orderlogs: responseData,
          notesPopup: true,
        });
      })
      .catch((error) => {
        this.setState({ isLoading: false });
        console.error("Error getting status:", error);
      });
  };

  hideNotePopup = () => {
    this.setState({
      notesPopup: false,
      noteError: false,
      orderlogs: "",
      note: "",
    });
  };

  updateNoteLog = () => {
    if (this.state.note.trim() !== "") {
      var loginDetails = {};
      if (sessionStorage.getItem("authData")) {
        loginDetails = JSON.parse(sessionStorage.getItem("authData"));
      }

      let noteURL = BaseUrl + "admin/orderlog";
      const notepayload = {
        orderID: this.state.currentOrder.OrderNumber,
        noteText: this.state.note,
        createdBy: loginDetails.adminName,
      };
      this.setState({ isLoading: true });

      http
        .post(noteURL, notepayload)
        .then(async (response) => {
          this.setState({
            notesPopup: false,
            isLoading: false,
            showPopup: true,
            uploadMessage: "Order Note submitted successfully!",
          });
        })
        .catch((error) => {
          this.setState({ isLoading: false });
          console.error("Error submitting note:", error);
        });
    } else {
      this.setState({ noteError: true });
    }
  };

  render() {
    {
      /* Changed 'View Details' onclick function to 'getOrderaccountdetails' by K2b sangeetha for getting account details of particular order  */
    }
    const columns = [
      {
        name: "Order #",
        selector: (row) => row.OrderNumber,
        sortable: true,
      },
      {
        name: "Account Code",
        selector: (row) => row.accountCode,
        sortable: true,
      },
      {
        name: "Payment Method",
        selector: (row) =>
          row.PaymentMethod === "A"
            ? row.OrderPaid === "YES"
              ? "Paid (Pay Later)"
              : "Pay Later"
            : "Online Payment",
        sortable: true,
      },
      {
        name: "Order Total (Excl. of VAT)",
        selector: (row) => this.orderTotal(row.items),
        sortable: true,
        sortFunction: (a, b) => {
          const totalA = this.orderTotalValue(a.items);
          const totalB = this.orderTotalValue(b.items);
          return totalA - totalB;
        },
      },
      {
        name: "Ordered Date",
        selector: (row) => moment(row.createdAt).format("DD/MM/YYYY"),
        sortable: false,
      },
      {
        name: "Delivery Date",
        selector: (row) => row.DateRequired,
        sortable: true,
      },
      {
        name: "Status",
        selector: (row) => row.OrderStatus,
        sortable: true,
      },

      {
        name: "Actions",
        selector: (row, index) => (
          <div className="order-action-tbl">
            <a
              className="title-tooltip title-up button"
              data-title="View Details"
              style={{ color: "white" }}
              onClick={() => this.getOrderaccountdetails(row.accountCode, row)}
            >
              <i className="fa fa-eye" aria-hidden="true"></i>
            </a>
            <a
              className="button title-tooltip title-up"
              data-title="Order Note Log"
              onClick={() => this.orderNotelog(row)}
            >
              <i className="fa fa-history"></i>
            </a>
            <a
              className="refresh-btn title-tooltip title-up"
              data-title="Click to view the updated status and details of this order"
              onClick={this.orderRefresh.bind(this, row)}
            >
              <i
                className="fa fa-refresh"
                id={"refresh_order_id" + row.OrderNumber}
                aria-hidden="true"
              ></i>{" "}
            </a>
          </div>
        ),
        sortable: false,
      },
    ];

    const customStyles = {
      rows: {
        style: {
          minHeight: "25px",
        },
      },
      headCells: {
        style: {
          padding: "8px", // override the cell padding for head cells
        },
      },
      cells: {
        style: {
          padding: "8px", // override the cell padding for data cells
        },
      },
    };

    const paginationComponentOptions = {
      rowsPerPageText: "",
      rangeSeparatorText: "",
      selectAllRowsItem: true,
      selectAllRowsItemText: "",
      noRowsPerPage: "",
    };
    const rangeOptions = [
      { value: "1", label: "Past 1 Month" },
      { value: "3", label: "Past 3 Months" },
      { value: "6", label: "Past 6 Months" },
      { value: "1Year", label: "Past 1 Year" },
      /*{ value: "Viewall", label: "View All" }*/
      { value: "custom", label: "Custom" },
    ];

    var datelabel = "";
    var dateValue = "";
    if (this.state.dateRange === "1") {
      datelabel = "Past 1 Month";
      dateValue = "1";
    } else if (this.state.dateRange === "3") {
      datelabel = "Past 3 Months";
      dateValue = "3";
    } else if (this.state.dateRange === "6") {
      datelabel = "Past 6 Months";
      dateValue = "6";
    } else if (this.state.dateRange === "365") {
      datelabel = "Past 1 Year";
      dateValue = "1Year";
    } else if (this.state.dateRange === "custom") {
      datelabel = "Custom";
      dateValue = "custom";
    } else if (this.state.dateRange === "") {
      datelabel = "Past 1 Month";
      dateValue = "1";
    }

    const nodatacomponent =
      this.state.searchstate === false ? (
        this.state.datefromLabel && this.state.datetoLabel ? (
          <div className="no-records-found">
            Sorry, there are no order records found from
            {this.formatDate(this.state.datefromLabel)} to
            {this.formatDate(this.state.datetoLabel)}. Please consider using
            more filter option to refine your search.
          </div>
        ) : (
          <div className="no-records-found">
            Sorry, there are no order records found for {this.state.datelabel}.
            Please consider using more filter option to refine your search.
          </div>
        )
      ) : (
        <div className="no-records-found">
          Sorry, no orders were matching your keyword, please try again with a
          different search term.
        </div>
      );

    return (
      <>
        {this.state.alertPopup === true && (
          <CommonAlert title="Alert" message={this.state.alertMessage} />
        )}
        <section className="dashboard-block">
          <Sidebar />

          <div className="dash-left">
            <Header />

            <div className="cart-block">
              <div className="container">
                <h1 className="checkout-title">Orders</h1>
                <div className="right-table-outer-wrap">
                  <div className="table-filter search-orders orders-search-bx">
                    <div className="orders-drop-left">
                      <div className="orders-dropdown">
                        <select
                          className="handle-srch"
                          ref={this.searchFieldRef}
                          onChange={this.handleSearchFieldChange}
                        >
                          <option value="" disabled>
                            Search by Field
                          </option>
                          <option value="OrderNumber">Order Number</option>
                          <option value="OrderStatus">Order Status</option>
                          <option value="accountCode">Account Code</option>
                          <option value="PaymentMethod">Payment Method</option>
                        </select>
                      </div>
                      <div className="table-search-reset">
                        <div className="table-search">
                          {this.renderSearchInput()}
                          {/* <input type="text" value={this.state.searchTextVal} onChange={this.handleChange} placeholder={`Search by ${this.state.searchFieldtxt}`} /> */}
                        </div>

                        <button
                          className="submitbtn button search-ord-btn"
                          onClick={(e) =>
                            this.filterData(this.state.searchTextVal)
                          }
                        >
                          <i className="fa fa-search"></i>
                        </button>
                        <button
                          className="resetbtn"
                          type="reset"
                          onClick={this.handleReset}
                        >
                          Reset
                        </button>
                      </div>
                    </div>
                    <div className="select-drop" ref={this.dropdownRef}>
                      <a
                        className={
                          this.state.isElementVisible == true
                            ? "filter-btn button active"
                            : "filter-btn button"
                        }
                        onClick={this.openFilter}
                      >
                        More Filter{" "}
                        <span className="filter-icon">
                          <i className="fa fa-sliders" aria-hidden="true"></i>
                        </span>
                      </a>
                      <div
                        className={
                          this.state.isElementVisible == true
                            ? "filter-bx show"
                            : "filter-bx"
                        }
                      >
                        <div className="filter-date-range">
                          <div className="fltr-dt-wrap">
                            <label>Date Range (Ordered Date):</label>
                            <Select
                              defaultValue={{
                                value: dateValue,
                                label: datelabel,
                              }}
                              options={rangeOptions}
                              theme={(theme) => ({
                                ...theme,
                                borderRadius: 6,
                                height: 40,
                                colors: {
                                  ...theme.colors,
                                  primary25: "#e0dedb",
                                  primary: "#ec2f45",
                                },
                              })}
                              onChange={this.handleFilterChange}
                            />
                          </div>
                        </div>
                        <div
                          className={
                            this.state.datepickershow == true
                              ? "fltr-dt-range show"
                              : "fltr-dt-range"
                          }
                        >
                          <div className="fltr--date">
                            <span>From</span>
                            <DatePicker
                              selected={this.state.datefrom}
                              placeholderText="From date"
                              showDisabledMonthNavigation
                              dateFormat="dd/MM/yyyy"
                              disablePast={true}
                              onChange={(date) => this.DateChange(date, "from")}
                            />
                            <span className="date-icon">
                              <i
                                className="fa fa-calendar"
                                aria-hidden="true"
                              ></i>
                            </span>
                          </div>
                          <div className="fltr--date">
                            <span>To</span>
                            <DatePicker
                              selected={this.state.dateto}
                              placeholderText="To date"
                              showDisabledMonthNavigation
                              dateFormat="dd/MM/yyyy"
                              disablePast={true}
                              onChange={(date) => this.DateChange(date, "to")}
                            />
                            <span className="date-icon">
                              <i
                                className="fa fa-calendar"
                                aria-hidden="true"
                              ></i>
                            </span>
                          </div>
                        </div>
                        {this.state.dateinvalid_error === true && (
                          <span className="color-red">
                            Invalid Start & End date!
                          </span>
                        )}
                        {this.state.datefill_error === true && (
                          <span className="color-red">
                            Please fill the from and to date!
                          </span>
                        )}
                        <div className="filter-btns">
                          <button
                            type="button"
                            className="button clear-btn"
                            onClick={this.clearFilterOrderSearch.bind(this)}
                          >
                            Clear
                          </button>
                          <button
                            type="button"
                            className="button apply-btn"
                            onClick={this.applyOrderFilters.bind(this)}
                          >
                            Apply{" "}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  {this.state.orders.length > 0 &&
                    this.state.loading === false && (
                      <p className="admin-order account-total-records">
                        Total Records:{" "}
                        {this.state.orders.length > 0
                          ? this.state.orders.length
                          : ""}
                        {/* {this.state.dateRange && (
                                        this.state.dateRange === 'custom' && this.state.datefrom && this.state.dateto ?  (
                                        <span>( From {this.formatDate(this.state.datefrom)} to {this.formatDate(this.state.dateto)})</span>
                                        ) : (
                                        ` (${this.state.datelabel})`
                                        )
                                    )} */}
                        {/*added by k2b sangeetha */}
                        {this.state.searchstate === false ? (
                          this.state.datefromLabel && this.state.datetoLabel ? (
                            <span>
                              {" "}
                              (From {this.formatDate(
                                this.state.datefromLabel
                              )}{" "}
                              to {this.formatDate(this.state.datetoLabel)})
                            </span>
                          ) : //` (${ this.state.datelabel })`
                          this.state.datelabel ? (
                            ` (${this.state.datelabel})`
                          ) : (
                            ""
                          )
                        ) : (
                          ""
                        )}
                        {/*added by k2b sangeetha */}
                      </p>
                    )}
                  <div className="checkout-table table_overflow orders-table">
                    <DataTable
                      columns={columns}
                      //  noDataComponent="Sorry, no orders were matching your keyword, try a new search."
                      noDataComponent={nodatacomponent}
                      data={this.state.orders}
                      customStyles={customStyles}
                      pagination
                      paginationPerPage={25}
                      progressPending={this.state.loading}
                      paginationRowsPerPageOptions={[25, 50, 75, 100]}
                      paginationComponentOptions={paginationComponentOptions}
                    />
                  </div>
                </div>
              </div>
              <Footer />
            </div>
          </div>
          {this.state.popupView}
        </section>

        {/* Loader & Popup START */}
        <div className="common">
          {/* <Alert extractId="success" customContent="Cart Added Success" /> */}
        </div>
        {/* Loader & Poupp END */}

        {this.state.notesPopup && (
          <Popup
            extClass="info-poup show"
            closebtn={
              <>
                <span onClick={this.hideNotePopup} className="close"></span>
              </>
            }
            headMoreTxt={
              <>
                <div className="order-top">
                  <h4>
                    <span>
                      Notes for Order - {this.state.currentOrder.OrderNumber}
                    </span>
                  </h4>
                </div>
              </>
            }
            bodyMoreTxt={
              <>
                <div className="bottom vendor-details ordernotes-log">
                  <div className="order-notes">
                    <div className="input-field">
                      <label for="note-label">Enter your note</label>
                      <textarea
                        name="note"
                        onChange={(e) => this.handleNoteChange(e.target.value)}
                      ></textarea>
                      {this.state.noteError && (
                        <div className="err-msg">Please update some note</div>
                      )}
                    </div>
                  </div>
                  <div className="alert-buttons btn-field btn-center">
                    <button
                      onClick={this.updateNoteLog}
                      className="popup-alert-btn button"
                    >
                      Submit
                    </button>
                    <button
                      onClick={this.hideNotePopup}
                      className="popup-alert-btn button"
                    >
                      Cancel
                    </button>
                  </div>
                  {this.state.isLoading ? (
                    <div className="loading-buffer"></div>
                  ) : (
                    <div className="order-notes-log">
                      {this.state.orderlogs &&
                        this.state.orderlogs.length > 0 &&
                        this.state.orderlogs.map((ord_item) => {
                          return (
                            <div className="info-item">
                              <div className="info-innr">
                                <p>
                                  <span className="labeltxt">Date: </span>
                                  {dateformatwithuktime(ord_item.createdAt)}
                                </p>
                                <p>
                                  <span className="labeltxt log-reason">
                                    Note:{" "}
                                  </span>
                                  {ord_item.noteText}
                                </p>
                                <p>
                                  <span className="labeltxt log-reason">
                                    Created by:{" "}
                                  </span>
                                  {ord_item.createdBy}
                                </p>
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  )}
                </div>
              </>
            }
          />
        )}

        {this.state.showPopup && (
          <div className="custom-alert-pop paylater-popup">
            <div className="custom-alert-container">
              <div className="custom-alert-wrap">
                <div className="custom-alert-header">
                  <h4>Alert</h4>
                </div>
                <div className="custom-alert-body">
                  <p>{this.state.uploadMessage}</p>

                  <div className="alert-buttons">
                    <button
                      onClick={this.closePopup}
                      className="popup-alert-btn button"
                    >
                      ok
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {this.state.refreshLoading && (
          <div className="custom-alert-pop paylater-popup">
            <div className="custom-alert-container">
              <div className="custom-alert-wrap">
                {/* <div className="custom-alert-header">
                  <h4>Fetching</h4>
                </div> */}
                <div className="custom-alert-body alert-no-header">
                  <div className="fth-latest-orders-alert">
                    <h4>Fetching latest order details</h4>
                    <p>
                      <i class="fa fa-spinner spin" aria-hidden="true"></i>{" "}
                      Please wait, we are fetching the latest order details...
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}

export default Orders;
