import React, { useState,useEffect} from 'react';
import Select from 'react-select';
import Popup from "../common/popup";
 

// Define the sanitizeString function
const sanitizeString = (input) => {
    // Remove spaces or commas at the beginning and end of the string
    let sanitized = input.trim().replace(/^,+|,+$/g, '');
  
    // // Remove spaces around commas
     sanitized = sanitized.trim();
  
    return sanitized;
  };

const editvendorDetails=(props) => {
    const [loading,setLoading] = useState(true);
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [formData, setFormData] = useState({
        cid:props.VendorDetails.accountCode,
        cname: props.VendorDetails.accountName || '',
        cemail: props.VendorDetails.accountEmail || '',
        caddress1: sanitizeString(props.VendorDetails.accountAddress1 || ''),
        caddress2: sanitizeString(props.VendorDetails.accountAddress2 || ''),
        caddress3: sanitizeString(props.VendorDetails.accountAddress3 || ''),
        caddress4: sanitizeString(props.VendorDetails.accountAddress4 || ''),
        caddress5: sanitizeString(props.VendorDetails.accountAddress5 || ''),
        cwebaccess: props.VendorDetails.accountWebSiteAccess,
        cpriceband: props.VendorDetails.accountPriceListId,
        ctelephone: props.VendorDetails.accountTelephone || '',
        cCreditLimit: props.VendorDetails.CreditLimit,
        caccountObjective1: props.VendorDetails.AccountObjective1,
        caccountObjective2: props.VendorDetails.AccountObjective2,
        cPaymentMethod: props.VendorDetails.accountPaymentMethod,
        cminimumOrderAmt: props.VendorDetails.minimumOrderAmount || '',
    });
    useEffect(() => {
        if (props.VendorDetails.accountDeliverableDays) {
            const days = props.VendorDetails.accountDeliverableDays.toLowerCase().split('/');
            //const selected = days.map(day => ({ value: day, label: day }));
            const selected = days.map(day => {
                const matchedOption = options.find(option => option.value.toLowerCase() === day);
                return matchedOption ? { value: matchedOption.value, label: matchedOption.label } : null;
            }).filter(day => day !== null);
            
            setSelectedOptions(selected);
        }
        setLoading(false);
    }, [props.VendorDetails]);
   
     const [errors, setErrors] = useState({});
     const [errorField, setErrorField] = useState(null);
     const options = [
        { value: 'All', label: 'All' },
        { value: 'Monday', label: 'Monday' },
        { value: 'Tuesday', label: 'Tuesday' },
        { value: 'Wednesday', label: 'Wednesday' },
        { value: 'Thursday', label: 'Thursday' },
        { value: 'Friday', label: 'Friday' },
        { value: 'Saturday', label: 'Saturday' },
        { value: 'Sunday', label: 'Sunday' },
    ];

   

    const handleValue = (event) => {
        const { name, value } = event.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));

        // Clear error message for the corresponding field
        setErrors(prevErrors => ({
            ...prevErrors,
            [name]: ''
        }));
    };

    const handleMultiSelectChange = (selected) => {
        setSelectedOptions(selected);
        if (selected.some(option => option.value === 'All')) {
            setSelectedOptions([{ value: 'All', label: 'All' }]);
          }
    };
    const editSubmit=()=>{
        
        const isValid = formvalidation();
        if(isValid)
        {   
           //props.editvendorDetails(formData);
           const updatedFormData = {
            ...formData,
            caddress1: sanitizeString(formData.caddress1),
            caddress2: sanitizeString(formData.caddress2),
            caddress3: sanitizeString(formData.caddress3),
            caddress4: sanitizeString(formData.caddress4),
            caddress5: sanitizeString(formData.caddress5),
            accountDeliverableDays: selectedOptions.map(option => option.value).join('/')
        };
        props.editvendorDetails(updatedFormData);
        }
        else {
            // Focus on the first field with an error message
           setErrorField(Object.keys(errors)[0]);
        }
    }

    const handleKeyDown = (event) => {
        // Get the key code of the pressed key
        const keyCode = event.keyCode || event.which;
        const key = event.key;
        // Allow only numeric keys (0-9), backspace, and delete keys
        const isValidKey =    ((keyCode >= 48 && keyCode <= 57) ||  // 0-9 keys
        (keyCode >= 96 && keyCode <= 105) || // Numpad keys
        keyCode === 8 || keyCode === 46 ||  // Backspace and delete keys
        keyCode === 37 || keyCode === 38 ||  // Left and up arrow keys
        keyCode === 39 || keyCode === 40    // Right and down arrow keys
    );

        // Allow only if the key is a valid numeric key or a non-numeric key
        if (!isValidKey || (isNaN(Number(key)) && key !== 'Backspace' && key !== 'Delete')) {
        event.preventDefault();
        }
      };

    const validateEmail=(email) => {
    //var emailRegex = /^[a-zA-Z0-9!#$%&'*+/=?^_{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_{|}~-]+)*@[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.[a-zA-Z]{2,}$/;
    var emailRegex = /^[a-zA-Z0-9!#$%&'*+/=?^_{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?/g;
    if (!emailRegex.test(email)) {
        return false;
    } else {
        return true;
    }
    }

    const formvalidation = () =>
    {        
        const newErrors = {};
        let isValid = true;

        if (formData.cname.trim() === '') {
            newErrors.cname = "Account Name is Required";
            isValid = false;
        }
        if (formData.cemail.trim() === '') {
            newErrors.cemail = "Account Email is Required";
            isValid = false;
        }
        else if (!validateEmail(formData.cemail.trim())) {
            newErrors.cemail = "Invalid Email";
            isValid = false;
        }
        if (formData.caddress1.trim() === '' && formData.caddress3.trim() === '' && formData.caddress5.trim() === '') {
            newErrors.caddress1 = "Address1 is Required";
            newErrors.caddress3 = "Address3 is Required";
            newErrors.caddress5 = "Address5 is Required";
            isValid = false;
        }
        if(formData.ctelephone.trim() === '')
        {
            newErrors.ctelephone = "Telephone Number is Required";
            isValid = false;  
        }
        
        if (formData.cwebaccess.trim() === '') { // Validation for Yes/No option
            newErrors.cwebaccess = "Please select an option";
            isValid = false;
        }

        if (formData.cCreditLimit.trim() === '') { 
            newErrors.cCreditLimit = "Credit Limit is Required";
            isValid = false;
        }
        // if (formData.cPaymentMethod.trim() === '') { 
        //     newErrors.cPaymentMethod = "Please select an option";
        //     isValid = false;
        // }

        setErrors(newErrors);
        return isValid;
    
        
    }

    const accObjectiveoptions = Array.from({ length: 21 }, (_, i) => i);

   
  return (
   
    <Popup
            extClass="order-pop info-poup"
            headMoreTxt={
                <>
                    <div className="order-top">
                    <div className="head">
                    <h4>Edit Customer Details</h4>
                    </div>
                   
                    </div>
                </>
            }
            bodyMoreTxt={
                
                        <div className="form-field bottom vendor-details">
                            <div className="input-field">
                            <span className="labeltxt">Account Code</span>
                                <input type='text'  name="cid" value={formData.cid} readOnly/>
                            </div>
                            <div className="input-field">
                            <span className="labeltxt">Name</span>
                                <input type='text'  name="cname" value={formData.cname} onChange={handleValue} />
                                {errors.cname && <div className='error-msg'>{errors.cname}</div>}
                            </div>
                            <div className="input-field col-2">
                            <span className="labeltxt">Email Address</span>
                                <input type='text' name="cemail" value={formData.cemail} onChange={handleValue}/>
                                {errors.cemail && <div className='error-msg'>{errors.cemail}</div>}
                            </div>
                            <div className="input-field col-2">
                                <span className="labeltxt">Telephone Number</span>
                                <input type='text' name="ctelephone" value={formData.ctelephone} onChange={handleValue}/>
                                {errors.ctelephone && <div className='error-msg'>{errors.ctelephone}</div>}
                            </div>
                           <div className="input-field col-2">
                            <span className="labeltxt">Address1</span>
                                <input type='text' name="caddress1" value={formData.caddress1} onChange={handleValue}/>
                                {errors.caddress1 && <div className='error-msg'>{errors.caddress1}</div>}
                            </div>
                            
                            <div className="input-field col-2">
                            <span className="labeltxt">Address2</span>
                                <input type='text' name="caddress2" value={formData.caddress2} onChange={handleValue}/>
                                {errors.caddress2 && <div className='error-msg'>{errors.caddress2}</div>}
                            </div>
                            
                            <div className="input-field col-2">
                            <span className="labeltxt">Address3</span>
                                <input type='text' name="caddress3" value={formData.caddress3} onChange={handleValue}/>
                                {errors.caddress3 && <div className='error-msg'>{errors.caddress3}</div>}
                            </div>
                            
                            <div className="input-field col-2">
                            <span className="labeltxt">Address4</span>
                                <input type='text' name="caddress4" value={formData.caddress4} onChange={handleValue}/>
                                {errors.caddress4 && <div className='error-msg'>{errors.caddress4}</div>}
                            </div>
                            
                            <div className="input-field">
                            <span className="labeltxt">Address5</span>
                                <input type='text' name="caddress5" value={formData.caddress5} onChange={handleValue}/>
                                {errors.caddress5 && <div className='error-msg'>{errors.caddress5}</div>}
                            </div>
                            <div className="input-field">
                            <span className="labeltxt">Minimum Order Amount</span>
                                <input type='text' name="cminimumOrderAmt" value={formData.cminimumOrderAmt} onKeyDown={handleKeyDown} onChange={handleValue}/>
                                <span className='text-note'>(Note: Default Minimum Order Amount is &pound;{sessionStorage.getItem('minimuOrderAmount')})</span>
                            </div>
                            <div className="input-field col-2">
                            <span className="labeltxt">Price Band</span>
                            <input type='text'  name="cpriceband" value={formData.cpriceband} readOnly/>
                            </div>

                            <div className="input-field col-2">
                            <span className="labeltxt">Credit Limit</span>
                                <input type='text' name="cCreditLimit" value={formData.cCreditLimit}  onKeyDown={handleKeyDown} onChange={handleValue}/>
                                {errors.cCreditLimit && <div className='error-msg'>{errors.cCreditLimit}</div>}
                            </div>

                            <div className="input-field col-2">
                            <span className="labeltxt">Accept CWO Only</span>
                                <input type='text' name='cPaymentMethod' value={formData.cPaymentMethod} readOnly/>
                                 {/* <select name="cPaymentMethod" value={formData.cPaymentMethod}  onChange={handleValue}>
                                    <option value="">Select</option>
                                    <option value="Yes">Yes</option>
                                    <option value="No">No</option>
                                </select> */}
                                {/* {errors.cPaymentMethod && <div className='err-msg'>{errors.cPaymentMethod}</div>} */}
                            </div>
                            <div className="input-field col-2">
                                <span className="labeltxt">Website Access</span>
                                <select name="cwebaccess" value={formData.cwebaccess} onChange={handleValue}>
                                    <option value="">Select</option>
                                    <option value="Yes">Yes</option>
                                    <option value="No">No</option>
                                </select>
                                {errors.cwebaccess && <div className='error-msg'>{errors.cwebaccess}</div>}
                            </div>
                            <div className="input-field col-2">
                                <span className="labeltxt">Account Objective 1</span>
                                <select name="caccountObjective1" value={formData.caccountObjective1} onChange={handleValue}>
                                    <option value="">Select</option>
                                    {accObjectiveoptions.map((number) => (
                                        <option key={number} value={number}>
                                            {number}
                                        </option>
                                        ))}
                                </select>
                                {errors.caccountObjective1 && <div className='error-msg'>{errors.caccountObjective1}</div>}
                            </div>
                            <div className="input-field col-2">
                                <span className="labeltxt">Account Objective 2</span>
                                <select name="caccountObjective2" value={formData.caccountObjective2} onChange={handleValue}>
                                    <option value="">Select</option>
                                    {accObjectiveoptions.map((number) => (
                                        <option key={number} value={number}>
                                            {number}
                                        </option>
                                        ))}
                                </select>
                                {errors.caccountObjective2 && <div className='error-msg'>{errors.caccountObjective2}</div>}
                            </div>

                            <div className='input-field'>
                            <span className='labeltxt'>Route Days</span>
                            <Select className='custom-react-select'
                                value={selectedOptions}
                                onChange={handleMultiSelectChange}
                                options={options}
                                isMulti
                                closeMenuOnSelect={false}
                                isOptionDisabled={(option) => option.value === 'Saturday' || option.value === 'Sunday'}

                             />    
                            </div>

                            <div className="btn-field btn-center"><button onClick={editSubmit}>Submit</button>&nbsp;&nbsp;<button onClick={(e)=>props.popupclose(e,false,'')}>Cancel</button></div>          
                        </div>
               
            }
                              
            closebtn={<><span onClick={(e)=>props.popupclose(e,false,'')} className='close'></span></>}

           
        />
  );
}

export default editvendorDetails
