import React, { useEffect, useState } from 'react';
import Header from '../common/layout/header';
import Footer from '../common/layout/footer';
import Sidebar from '../common/sidebar/index'
import Chart from "chart.js/auto";
import { CategoryScale } from "chart.js";
import { BarChart } from './Barchart';
import { PieChart } from './piechart';
import { TopSellProducts } from './Topsellingproducts';
import { TopCustomers } from './TopCustomers';
import { http } from '../helper';
import { BaseUrl } from '../common/config';

Chart.register(CategoryScale);

const Dashboard = () => {


    const [paylaterOrders, setPaylaterOrders] = useState(0);
    const [onlinePaymentOrders, setOnlinePaymentOrders] = useState(0);
    const [topSellingProducts, setTopSellingProducts] = useState([]);
    const [topOrderedCustomers, setTopOrderedCustomers] = useState([]);
    const [chartData, setChartData] = useState({
        labels: ['Online Payment Orders', 'Pay Later Orders'],
        datasets: [
            {
                label: "Orders",
                data: [onlinePaymentOrders, paylaterOrders],
                backgroundColor: [
                    "rgba(252,194,84,1)",
                    "#f4ecec",

                ],
                borderColor: "black",
                borderWidth: 2
            }
        ]
    });
    const [topSellerChartData, setTopSellerChartData] = useState({
        labels: [],
        datasets: [
            {
                label: "Top Selling Products",
                data: [],
                backgroundColor: [
                    "rgba(252,194,84,1)",
                    "#f4ecec",

                ],
                borderColor: "black",
                borderWidth: 2
            }
        ]
    });




    useEffect(() => {

        let URL3 = BaseUrl + "admin/getmonthlyreport";
        http.get(URL3).then((response) => {

            setTopSellerChartData({
                labels: response.data.data.map((item) => item._id.month + "/" + item._id.year),
                datasets: [
                    {
                        label: "Total Revenue",
                        data: response.data.data.map((item) => item.amount),
                        backgroundColor: [
                            "rgba(252,194,84,1)",
                            "#f4ecec",

                        ],
                        borderColor: "black",
                        borderWidth: 2
                    }
                ]
            });

        }).catch((err) => {
            console.log(err);
        })


        let URL = BaseUrl + "admin/paymentorders";
        http.get(URL).then((response) => {

            setOnlinePaymentOrders(response?.data?.data?.onlinePaymentOrders);
            setPaylaterOrders(response?.data?.data?.payLaterOrders);

        }).catch((err) => {
            console.log(err);
        })
        let URL1 = BaseUrl + "admin/topsellers";
        http.get(URL1).then((response) => {
            setTopSellingProducts(response.data.data);


        }).catch((err) => {
            console.log(err);
        })
        let URL2 = BaseUrl + "admin/topcustomers";
        http.get(URL2).then((response) => {
            setTopOrderedCustomers(response.data.data);


        }).catch((err) => {
            console.log(err);
        })

    }, []);

    useEffect(() => {

        setChartData({
            labels: ['Online Payment Orders', 'Pay Later Orders'],
            datasets: [
                {
                    label: "Orders",
                    data: [onlinePaymentOrders, paylaterOrders],
                    backgroundColor: [
                        "rgba(252,194,84,1)",
                        "#fbfbfb",

                    ],
                    borderColor: "black",
                    borderWidth: 2
                }
            ]
        });
    }, [onlinePaymentOrders, paylaterOrders])


    return (
        <section className="dashboard-block">


            <Sidebar />

            <div className="dash-left">
                <Header />
                <section className="cart-block">
                    <div className="container">
                        <h1 className="checkout-title">
                            Dashboard
                        </h1>

                        <div className="right-table-outer-wrap">
                            <div className="flex-container">
                                <div className="flex-left" style={{ verticalAlign: "top" }}>
                                    {topSellerChartData && <BarChart chartData={topSellerChartData} />}
                                </div>
                                <div className="flex-right" style={{ verticalAlign: "top" }}>
                                    {chartData && <PieChart chartData={chartData} />}
                                </div>

                            </div>
                            <div className="flex-container">
                                <div className="flex-left" style={{ verticalAlign: "top" }}>
                                    {topOrderedCustomers && <TopCustomers customers={topOrderedCustomers} />}
                                </div>
                                <div className="flex-right" style={{ verticalAlign: "top" }}>
                                    {topSellingProducts && <TopSellProducts topProducts={topSellingProducts} />}
                                </div>

                            </div>
                        </div></div>
                </section>

                <Footer />
            </div>

        </section>);



}

export default Dashboard;